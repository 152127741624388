import React from 'react';
import styled from 'styled-components';
import { breakpoints, colors, fonts } from '../../style-utilities/variables';
// import components
import WhitePaperGridItem from './WhitePaperGridItem';
const StyledWhitePaperGrid = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: 1fr 1.5em 1fr 1.5em 1fr 1.5em 1fr 1.5em 1fr 1.5em 1fr;
  grid-template-columns: 1;
  grid-template-rows: 1fr;
  grid-gap: 1.5em;
  @media (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
    -ms-grid-rows: 1fr 1.5em 1fr 1.5em 1fr;
    -ms-grid-columns: 1fr 2em 1fr;
    grid-gap: 2em;

    .grid-item:nth-child(1) {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }
    .grid-item:nth-child(2) {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }
    .grid-item:nth-child(3) {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
    }
    .grid-item:nth-child(4) {
      -ms-grid-row: 3;
      -ms-grid-column: 3;
    }
    .grid-item:nth-child(5) {
      -ms-grid-row: 1;
      -ms-grid-row: 5;
    }
    .grid-item:nth-child(6) {
      -ms-grid-row: 5;
      -ms-grid-column: 3;
    }
  }
`;

const WhitePaperGrid = ({ posts }) => {
  return (
    <StyledWhitePaperGrid className="insights-grid">
      {posts.map(({ node }, index) => {
        return (
          <WhitePaperGridItem
            key={node.slug}
            item={node}
            index={index}
            className="grid-item"
          />
        );
      })}
    </StyledWhitePaperGrid>
  );
};

export default WhitePaperGrid;
