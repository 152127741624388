import React from "react";
import { Link } from "gatsby";
import { colors, fonts, breakpoints } from "../../style-utilities/variables";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import _ from "lodash";

import ButtonGrey from "../ButtonGrey";

const Div = styled.div`
  border: 2px solid ${colors.secondaryGray};
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  .image-box {
    width: 100%;
    overflow: hidden; /* need overflow: hidden for ie  */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0;
    img {
      margin-bottom: 0;
    }
  }
  .title-link {
    margin-bottom: 0.5em;
    @media (max-width: ${breakpoints.tabletMax}) {
      font-size: 1.25em;
    }
  }
  .title-link:hover {
    color: ${colors.black};
  }


  .item-content {
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1em;
    background-color: ${colors.white};
    a:not(.linkColor, .title-link) {
      color: ${colors.primaryGray};
      text-decoration: underline;
    }
  }
  .post-excerpt {
    flex: 1 1 auto;
  }
  @media (min-width: ${breakpoints.tablet}) {
    .item-content {
      padding: 2em 1em;
    }
  }

`;

const WhitePaperGridItem = ({ item, index, className }) => {
  return (
    <Div className={className} key={item.slug}>
      <div className="image-box">
        <Link to={"/insights/white-papers/" + item.slug} className="title-link">
          <img src={item.better_featured_image.source_url} alt={item.title} />
        </Link>
      </div>
      <div className="item-content">
        <Link
          to={"/insights/white-papers/" + item.slug}
          className="title-link post-title"
        >
          {ReactHtmlParser(item.title)}
        </Link>
        <div className="post-excerpt">{ReactHtmlParser(item.acf.excerpt)}</div>
        <div className="post-link">
          <ButtonGrey
            textLabel="Read More"
            pageLink={"/insights/white-papers/" + item.slug}
          />
        </div>
      </div>
    </Div>
  );
};

export default WhitePaperGridItem;
