import React, { Component } from "react";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
import { colors, fonts, breakpoints } from "../../style-utilities/variables";

// import components
import Arrow from "../../components/Arrow";
import ArrowLeft from "../../components/ArrowLeft";
import WhitePaperGrid from "../../components/WhitePapersGrid/WhitePaperGrid";
import TinyPagination from "./../../components/TinyPagination";
import Filters from "./../../components/Filters";
import SEO from "./../../components/SEO";
import Hero from "./../../components/Hero";
import DefaultSharingImage from "./../../static/ansira-logo-sharing.png";
import ContactBanner from "./../../components/ContactBanner";

const StyledWhitePapers = styled.div`
  margin: 0.75em auto;
  max-width: 1500px;
  .no-posts {
    text-align: center;
  }
`;

class WhitePapers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setSortValue: {},
      setTopicValue: {},
      setServiceValue: {},
      setVerticalValue: {},
      sortValue: "",
      topicValue: "",
      topicId: "",
      serviceValue: "",
      serviceId: "",
      verticalValue: "",
      valueId: "",
      isBrowser: typeof window !== "undefined",
      selectedPageId: 1
    };

    this.changePage = this.changePage.bind(this);
    this.renderBtnNumber = this.renderBtnNumber.bind(this);
  }

  /**
   * METHOD: Scroll to top on pagination click needs scrollStep
   */
  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }
  scrollToTop() {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    );
    this.setState({ intervalId: intervalId });
  }

  /**
   * METHOD: Change page on Pagination
   * @param: id of the current page
   */
  changePage(id) {
    this.setState(prevState => {
      return {
        ...prevState,
        selectedPageId: id
      };
    });
  }
  /**
   * METHOD: for button Prev and Next on Pagination
   * @param: id of the current page
   */
  buttonPageClick(id) {
    let { selectedPageId } = this.state;
    switch (id) {
      case "<":
        this.changePage(selectedPageId - 1);
        break;
      case ">":
        this.changePage(selectedPageId + 1);
        break;
      default:
        this.changePage(id);
        break;
    }
    this.scrollToTop();
  }
  /**
   * METHOD: Render button to the UI
   * @param: id of the current page
   */
  renderBtnNumber = id => {
    let { selectedPageId } = this.state;

    let buttonContent = id;
    if (id == "<") {
      buttonContent = <ArrowLeft />;
    }
    if (id == ">") {
      buttonContent = <Arrow />;
    }

    return (
      <button
        onClick={this.buttonPageClick.bind(this, id)}
        key={id}
        className={`page ${selectedPageId === id ? "selected-page" : ""}`}
      >
        {buttonContent}
      </button>
    );
  };

  /* Get Topic Service and Vertical value from filter and store for pagination*/
  handleTopic = e => {
    if (this.state.isBrowser) {
      window.localStorage.setItem(
        "handleTopic",
        e ? JSON.stringify(e) : window.localStorage.removeItem("handleTopic")
      );
    }
    this.setState({
      topicValue: e ? e.value : null,
      setTopicValue: e ? e : null,
      selectedPageId: 1
    });
  };
  handleService = e => {
    if (this.state.isBrowser) {
      window.localStorage.setItem(
        "handleService",
        e ? JSON.stringify(e) : window.localStorage.removeItem("handleService")
      );
    }
    this.setState({
      serviceValue: e ? e.value : null,
      setServiceValue: e ? e : null,
      selectedPageId: 1
    });
  };
  handleVertical = e => {
    if (this.state.isBrowser) {
      window.localStorage.setItem(
        "handleVertical",
        e ? JSON.stringify(e) : window.localStorage.removeItem("handleVertical")
      );
    }
    this.setState({
      verticalValue: e ? e.value : null,
      setVerticalValue: e ? e : null,
      selectedPageId: 1
    });
  };
  handleSort = e => {
    if (this.state.isBrowser) {
      window.localStorage.setItem(
        "handleSort",
        e ? JSON.stringify(e) : window.localStorage.removeItem("handleSort")
      );
    }
    this.setState({
      sortValue: e ? e.value : null,
      setSortValue: e ? e : null
    });
  };

  render() {
    const props = this.props;
    let topics = props.data.allWordpressWpTopic;
    let service = props.data.allWordpressWpOurSolutions;
    let vertical = props.data.allWordpressWpVertical;
    let posts = props.data.allWordpressWpWhitePaper.edges;
    let page = props.data.wordpressPage;

    let filteredPosts = [];
    const topicsList = [];
    const servicesList = [];
    const verticalsList = [];
    let usedTopics = [];
    let usedServices = [];
    let usedVerticals = [];
    let selectedValuesArray = [];
    let queryParameter = "";
    let queryNumber = "";
    let topicPlaceholder = "Topic";

    if (typeof document !== "undefined") {
      queryParameter = window.location.href.split("=");
      queryNumber = parseInt(queryParameter[1]);
    }

    if (queryParameter.length > 1) {
      selectedValuesArray.push(parseInt(queryParameter[1]));
    }

    //if item in Array of selected state matches items in single post, push that post to a new posts array and send to Blog Grid
    if (this.state.topicValue != "" && this.state.topicValue != null) {
      selectedValuesArray.push(this.state.topicValue);
    }
    if (this.state.serviceValue != "" && this.state.serviceValue != null) {
      selectedValuesArray.push(this.state.serviceValue);
    }
    if (this.state.verticalValue != "" && this.state.verticalValue != null) {
      selectedValuesArray.push(this.state.verticalValue);
    }

    posts.map((post, i) => {
      let thisPostsValues = [];

      posts[i].node.topic.map((topic, j) => {
        usedTopics.push(topic);
        thisPostsValues.push(posts[i].node.topic[j]);
        if (
          posts[i].node.topic[j] == this.state.topicValue &&
          this.state.serviceValue == "" &&
          this.state.verticalValue == ""
        ) {
          filteredPosts.push(post);
        }
      });
      posts[i].node.our_solutions.map((services, k) => {
        usedServices.push(services);
        thisPostsValues.push(posts[i].node.our_solutions[k]);
        if (
          posts[i].node.our_solutions[k] == this.state.serviceValue &&
          this.state.topicValue == "" &&
          this.state.verticalValue == ""
        ) {
          filteredPosts.push(post);
        }
      });
      posts[i].node.vertical.map((vertical, l) => {
        usedVerticals.push(vertical);
        thisPostsValues.push(posts[i].node.vertical[l]);
        if (
          posts[i].node.vertical[l] == this.state.verticalValue &&
          this.state.serviceValue == "" &&
          this.state.topicValue == ""
        ) {
          filteredPosts.push(post);
        }
      });
      if (
        selectedValuesArray.every(function(item) {
          return thisPostsValues.indexOf(item) !== -1;
        }) == true
      ) {
        filteredPosts.push(post);
      }
    });

    //Check if no results on filter to trigger GTM event
    if (typeof document !== "undefined") {
      let arrPostItems = document.getElementsByClassName("insights-grid");
      let postItem = arrPostItems.item(0);
      if (postItem != null && postItem.innerHTML === "") {
        if (filteredPosts.length === 0) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "noFilterResults"
          });
        }
      }
    }

    //function to remove duplicates in filteredPosts array
    function removeDuplicates(arr) {
      const final = [];
      arr.map((e, i) => !final.includes(e) && final.push(e));
      return final;
    }

    removeDuplicates(usedTopics);
    removeDuplicates(usedServices);
    filteredPosts = removeDuplicates(filteredPosts);

    let allUsedTopics = [];
    let allUsedServices = [];

    topics.edges.map((topic, index) => {
      for (var i = 0; i < usedTopics.length; i++) {
        if (usedTopics[i] == topics.edges[index].node.wordpress_id) {
          allUsedTopics.push(topic);
        }
      }
    });
    service.edges.map((serv, index) => {
      for (var i = 0; i < usedServices.length; i++) {
        if (usedServices[i] == service.edges[index].node.wordpress_id) {
          allUsedServices.push(serv);
        }
      }
    });
    for (var key in topics.edges) {
      if (topics.edges[key].node.slug == this.state.topicValue) {
        this.setState({ topicValue: topics.edges[key].node.wordpress_id });
      }
      if (topics.edges[key].node.wordpress_id == queryNumber) {
        topicPlaceholder = topics.edges[key].node.name;
      }
    }

    for (var key in service.edges) {
      if (service.edges[key].node.slug == this.state.serviceValue) {
        this.setState({ serviceValue: service.edges[key].node.wordpress_id });
      }
    }
    for (var key in vertical.edges) {
      if (vertical.edges[key].node.slug == this.state.verticalValue) {
        this.setState({ verticalValue: vertical.edges[key].node.wordpress_id });
      }
    }

    // sort all posts by most popular
    let popularPosts = [];
    if (this.state.sortValue == "most-popular") {
      popularPosts = Object.assign([], posts).sort(function(a, b) {
        return b.node.acf.popularity_num - a.node.acf.popularity_num;
      });
      posts = popularPosts;
    } else {
      posts = props.data.allWordpressWpWhitePaper.edges;
    }

    // sort filtered posts by most popular
    let popularFiltered = [];
    if (this.state.sortValue == "most-popular" && filteredPosts != 0) {
      popularFiltered = Object.assign([], filteredPosts).sort(function(a, b) {
        return b.node.acf.popularity_num - a.node.acf.popularity_num;
      });
      filteredPosts = popularFiltered;
    } else if (this.state.sortValue == "most-recent" && filteredPosts != 0) {
      filteredPosts = filteredPosts;
    }

    // Here we are grabbing content for filter dropdowns, the react-select library requires a specific format so we need to rename (name, slug) to be (value, label)
    removeDuplicates(allUsedTopics).forEach((item, index) => {
      topicsList[index] = {
        value: item.node.slug,
        label: item.node.name
      };
    });
    removeDuplicates(allUsedServices).forEach((item, index) => {
      servicesList[index] = {
        value: item.node.slug,
        label: item.node.name
      };
    });

    props.data.allWordpressWpVertical.edges.forEach((item, index) => {
      verticalsList[index] = {
        value: item.node.slug,
        label: item.node.name
      }
    });

    const itemPerPage = 6;
    let removeDummyPost = posts.filter(
      data => data.node.slug !== "do-not-delete"
    );

    let defaultPosts = [...removeDummyPost];
    defaultPosts = defaultPosts.splice(
      (this.state.selectedPageId - 1) * itemPerPage,
      itemPerPage
    );
    let conditionalPosts = removeDuplicates(filteredPosts);
    conditionalPosts = conditionalPosts.filter(
      data => data.node.slug !== "do-not-delete"
    );
    conditionalPosts = conditionalPosts.splice(
      (this.state.selectedPageId - 1) * itemPerPage,
      itemPerPage
    );

    return (
      <StyledWhitePapers className="animate">
        <SEO
          isBlogPost={false}
          postData={page}
          postImage={DefaultSharingImage}
        />
        <Hero
          id="featured"
          headline={page.acf.hero_headline}
          sub={page.acf.hero_sub_headline}
          bgcolor="black"
          textcolor={colors.white}
        />
        <div className="post-area page-container">
          <Filters
            setTopicValue={
              this.state.setTopicValue !== null &&
              Object.getOwnPropertyNames(this.state.setTopicValue).length !== 0
                ? this.state.setTopicValue
                : null
            }
            setServiceValue={
              this.state.setServiceValue !== null &&
              Object.getOwnPropertyNames(this.state.setServiceValue).length !==
                0
                ? this.state.setServiceValue
                : null
            }
            setVerticalValue={
              this.state.setVerticalValue !== null &&
              Object.getOwnPropertyNames(this.state.setVerticalValue).length !==
                0
                ? this.state.setVerticalValue
                : null
            }
            topics={topicsList}
            topicPlaceholder={topicPlaceholder}
            services={servicesList}
            verticals={verticalsList}
            changeSort={this.handleSort}
            changeTopic={this.handleTopic}
            changeService={this.handleService}
            changeVertical={this.handleVertical}
          />
          {filteredPosts.length === 0 && selectedValuesArray.length == 0 ? (
            <div>
              <WhitePaperGrid posts={defaultPosts} />
              <TinyPagination
                total={posts.length}
                selectedPageId={this.state.selectedPageId}
                itemPerPage={itemPerPage}
                renderBtnNumber={this.renderBtnNumber}
                maxBtnNumbers={5}
                preKey="<"
                nextKey=">"
              />
            </div>
          ) : (
            <div>
              {filteredPosts.length > 0 && conditionalPosts.length > 0 ? (
                <div>
                  <WhitePaperGrid posts={conditionalPosts} />
                  {filteredPosts > conditionalPosts ? (
                    <TinyPagination
                      total={filteredPosts.length}
                      selectedPageId={this.state.selectedPageId}
                      itemPerPage={itemPerPage}
                      renderBtnNumber={this.renderBtnNumber}
                      maxBtnNumbers={4}
                      preKey="<"
                      nextKey=">"
                    />
                  ) : (
                    <TinyPagination
                      total={filteredPosts.length}
                      selectedPageId={this.state.selectedPageId}
                      itemPerPage={itemPerPage}
                      renderBtnNumber={this.renderBtnNumber}
                      maxBtnNumbers={4}
                      preKey="<"
                      nextKey=">"
                    />
                  )}
                </div>
              ) : (
                <div className="no-posts">
                  <p>
                    Sorry there are no white papers with the selected filters.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        <ContactBanner
          headline={page.acf.contact_headline}
          cta={page.acf.contact_cta}
          url={page.acf.contact_url}
        />
      </StyledWhitePapers>
    );
  }
}

export default WhitePapers;

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "white-papers" }) {
      acf {
        hero_headline
        hero_sub_headline
        overview_headline
        contact_headline
        contact_cta
        contact_url
        seo_title
        seo_canonical
        seo_description
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
    allWordpressWpWhitePaper {
      edges {
        node {
          our_solutions
          topic
          vertical
          acf {
            excerpt
            popularity_num
          }
          better_featured_image {
            source_url
          }
          slug
          title
          type
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
            yoast_wpseo_canonical
          }
        }
      }
    }
    allWordpressWpTopic {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
    allWordpressWpOurSolutions {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
    allWordpressWpVertical {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
  }
`;
