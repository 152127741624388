import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { colors, breakpoints, fonts } from "../../style-utilities/variables";
import ReactHtmlParser from "react-html-parser";
import ButtonGrey from "../ButtonGrey";

const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  border: 2px solid ${colors.secondaryGray};
  .post-topic {
    color: ${colors.blue};
    font-weight: bold;
    margin-bottom: 3px;
    text-transform: uppercase;
  }
  .image-box {
    background-color: ${colors.white};
    width: 100%;
    overflow: hidden; /* need overflow: hidden for ie  */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0;
    img {
      margin-bottom: 0;
    }
  }
  .post-details {
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1em;
    background-color: ${colors.white};
  }
  .post-author-date {
    margin-bottom: 0.75em;
  }
  .post-excerpt {
    flex: 1 1 auto; /* must use this shorthand for IE */
  }
  .post-link {
    height: auto;
  }
`;

function randID() {
  var number = Math.random(); // 0.9394456857981651
  number.toString(36);
  var id = number.toString(36).substr(2, 9);
  return id;
}

const BlogPostItem = ({ isGrid, item, index, className, tags, data }) => {
  //create array of all tags
  var topicIDs = item.topic;
  var oldTopic = "";
  var primaryTopic = "";
  topicIDs.slice(0, 1).map((item, index) => {
    oldTopic = topicIDs[index];
  });

  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressWpTopic {
            edges {
              node {
                id
                name
                description
                wordpress_id
              }
            }
          }
        }
      `}
      render={data => {
        const tags = data.allWordpressWpTopic;
        var taglabels = [];
        for (var key in tags.edges) {
          taglabels[tags.edges[key].node.wordpress_id] =
            tags.edges[key].node.name;
        }

        if (item.acf.primary_topic && item.acf.primary_topic !== null) {
          primaryTopic = item.acf.primary_topic;
        } else {
          primaryTopic = taglabels[oldTopic];
        }

        return (
          <Div className={className}>
            <div className="image-box">
              <Link to={"/insights/blog/" + item.slug}>
                <img
                  src={item.better_featured_image.source_url}
                  alt={item.title}
                />
              </Link>
            </div>

            <div className="post-details">
              <p className="post-topic">{ReactHtmlParser(primaryTopic)}</p>
              <p className="post-author-date">
                By{" "}
                <Link to={`/blog/author/` + item.author.slug}>
                  {item.author.name}
                </Link>
                <span className="bar">{" | "}</span>
                <span className="postDate">{item.date}</span>
              </p>
              <Link to={"/insights/blog/" + item.slug} className="post-title">
                {item.title}
              </Link>
              <div className="post-excerpt">
                {ReactHtmlParser(item.acf.excerpt)}
              </div>
              <div className="post-link">
                <ButtonGrey
                  textLabel="Read More"
                  pageLink={"/insights/blog/" + item.slug}
                />
              </div>
            </div>
          </Div>
        );
      }}
    />
  );
};
export default BlogPostItem;
