import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { colors, breakpoints, fonts } from "../style-utilities/variables";

const Div = styled.div`
  margin: 2em auto;
  button {
    padding: 0;
    width: 24px;
    margin: 0 5px;
    width: 2.5em;
    height: 2.5em;
    color: ${colors.primaryGray};
    outline: none;
    border: none;
    background: none;
  }

  .btns-number-container {
    display: flex;
    justify-content: center;
  }
  .selected-page {
    font-weight: bold;
  }
`;

class TinyPagination extends React.Component {
  renderPreBtn(totalBtns, selectedPageId) {
    const { preKey } = this.props;
    const preLabel = preKey ? preKey : "<";
    return this.props.renderBtnNumber(preLabel);
  }

  renderNextBtn(totalBtns, selectedPageId) {
    const { nextKey } = this.props;
    const nextLabel = nextKey ? nextKey : ">";
    return this.props.renderBtnNumber(nextLabel);
  }

  renderBtnNumbers(totalBtns, selectedPageId, maxBtnNumbers, maxBtnPerSide) {
    let { spreadClass, spreadStyle } = this.props;
    let btns = [];
    if (totalBtns < maxBtnNumbers) {
      for (let i = 1; i <= totalBtns; i++) {
        btns.push(i);
      }
    } else {
      let betweenArray = [];
      let first =
        selectedPageId - maxBtnPerSide > 1 ? selectedPageId - maxBtnPerSide : 1;
      let last =
        selectedPageId + maxBtnPerSide > totalBtns
          ? totalBtns
          : selectedPageId + maxBtnPerSide;
      for (let i = first; i <= last; i++) {
        betweenArray.push(i);
      }
      if (first !== 1) {
        btns.push(1);
      }
      if (first > 2) {
        btns.push(-1);
      }
      btns = [...btns, ...betweenArray];
      if (last < totalBtns) {
        btns.push(-2);
      }
      if (last !== totalBtns) {
        btns.push(totalBtns);
      }
    }
    return btns.map(number => {
      if (number > 0) {
        return this.props.renderBtnNumber(number);
      } else {
        return (
          <div
            key={number}
            className={`spread ${spreadClass ? spreadClass : ""}`}
            style={spreadStyle}
          >
            ...
          </div>
        );
      }
    });
  }

  render() {
    let {
      total,
      selectedPageId,
      itemPerPage,
      maxBtnNumbers,
      wrapStyle,
      display,
      wrapClass,
      counterClass,
      counterStyle,
      btnsClass,
      btnsStyle,
      maxBtnPerSide = 1
    } = this.props;
    let totalBtns = parseInt(total / itemPerPage, 0);
    totalBtns = totalBtns * itemPerPage < total ? totalBtns + 1 : totalBtns;
    if (total <= 6) {
      return null;
    } else
      return (
        <Div
          className={`tiny-pagination-container ${wrapClass ? wrapClass : ""}`}
        >
          <div
            className={`btns-number-container ${btnsClass}`}
            style={btnsStyle}
          >
            {this.renderPreBtn(totalBtns, selectedPageId)}
            {this.renderBtnNumbers(
              totalBtns,
              selectedPageId,
              maxBtnNumbers,
              maxBtnPerSide
            )}
            {this.renderNextBtn(totalBtns, selectedPageId)}
          </div>
        </Div>
      );
  }
}
TinyPagination.propTypes = {
  total: PropTypes.number.isRequired,
  selectedPageId: PropTypes.number.isRequired,
  itemPerPage: PropTypes.number.isRequired,
  renderBtnNumber: PropTypes.func.isRequired,
  maxBtnNumbers: PropTypes.number.isRequired,
  wrapStyle: PropTypes.object,
  wrapClass: PropTypes.string,
  counterClass: PropTypes.string,
  counterStyle: PropTypes.object,
  btnsClass: PropTypes.string,
  btnsStyle: PropTypes.object,
  spreadClass: PropTypes.string,
  spreadStyle: PropTypes.object,
  preKey: PropTypes.string,
  nextKey: PropTypes.string
};

export default TinyPagination;
